<template>
    <div class="container">
      <div class="title">大嘴鸟信奥题库</div>
      <el-button class="custom-button red-button" @click="gotoHomework">课后练习</el-button>
      <el-button class="custom-button blue-button" @click="gotoQuestionnaire">错题库</el-button>
      <el-button class="custom-button green-button" @click="showMessage">敬请期待</el-button>
    </div>
  </template>
  
  <script>
  export default {
    data () {
      return {
        
      }
    },
    methods: {
      gotoHomework () {
          this.$router.push('/exam/homework')
      },
      gotoQuestionnaire () {
        this.$message.error('敬请期待');
      },
      showMessage () {
        this.$message.error('敬请期待');
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: url(../../../assets/image/exambackdrop.jpg) no-repeat center center;
    background-size: cover;
    // 设置了10像素的间距
    gap: 38px;
    .title {
      font-size: 48px;
      font-weight: bold;
      color: #333;
      margin-bottom: 24px;
    }
    .custom-button {
      width: 400px;
      height: 120px;
      font-size: 40px;
      border-radius: 5px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    .red-button {
      background-color: #f44336;
      color: white;
    }
    .blue-button {
      background-color: #2196f3;
      color: white;
    }
    .green-button {
      background-color: #4caf50;
      color: white;
    }
  }
  </style>